import React from "react"
import styled from "styled-components"
// @ts-ignore
import { ReactComponent as Arrow } from "../../../static/icons/ic-arrow-right.svg"

type ArrowedButtonProps = {
  withBackground?: boolean
  clickable?: boolean
}

const Button = styled.button<ArrowedButtonProps>`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  outline: none;
  color: ${props => (props.withBackground ? "white" : props.theme.textColor)};
  border: none;
  background: ${props =>
    props.withBackground ? props.theme.primaryColor : "transparent"};
  border-radius: 20px;
  padding: 14px 15px 14px 15px;
  display: flex;

  svg {
    padding-left: 9px;
    padding-top: 2px;
    transition: all 250ms ease-out;
    transform-origin: center;
  }

  &:hover {
    ${props => props.clickable && "cursor: pointer;"};
  }
`

type ComponentProps = {
  onClick?: () => void
  arrowIcon?: any
  [other: string]: any
}

export const ArrowedButton: React.FC<
  ArrowedButtonProps & ComponentProps
> = props => (
  <Button {...props}>
    {props.children} {props.arrowIcon || <Arrow className="arrow" />}
  </Button>
)
