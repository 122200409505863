import React, { useMemo } from "react";
import { ThemeProvider } from "styled-components";
import { DarkTheme } from "../../theme/DarkTheme";
import * as S from "./styled";

type MenuProps = {
  open: false;
  onClose: () => void;
  entries: {
    label: string;
    order: number;
    props: any;
  }[];
  header: {
    label: string;
    props: any;
  };
};

const MenuComponent: React.FC<MenuProps> = ({
  open,
  onClose,
  entries,
  header,
}) => {
  const menuContents = useMemo(
    () => (
      <S.Trends>
        <S.Header {...header.props} onClick={onClose}>
          {header.label}
        </S.Header>

        <S.TrendList>
          {entries.map(({ order, label, props }) => (
            <S.TrendRow key={label + order} {...props} onClick={onClose}>
              <S.TrendOrder>{String(order).padStart(2, "0")}.</S.TrendOrder>{" "}
              {label}
            </S.TrendRow>
          ))}
        </S.TrendList>
      </S.Trends>
    ),
    [entries]
  );
  return (
    <ThemeProvider theme={DarkTheme}>
      <S.Container menuOpen={open}>{menuContents}</S.Container>
    </ThemeProvider>
  );
};

export const Menu = React.memo(MenuComponent);
