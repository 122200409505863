import styled, { css } from "styled-components";
import { deviceMediaQuery } from "../../util";

type ContainerProps = {
  menuOpen: boolean;
};

export const Container = styled.nav<ContainerProps>`
  background: ${(props) => props.theme.backgroundColor};
  color: ${(props) => props.theme.textColor};
  border-radius: 3px;
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 32px;
  left: 10px;
  padding: 35px 20px 0;
  transition: all 250ms ease-out;
  opacity: 0;
  ${(props) =>
    props.menuOpen
      ? css`
          opacity: 0.9;
        `
      : css`
          pointer-events: none;
        `};
  z-index: 100;
  margin: auto 44px auto 33%;

  @media (${deviceMediaQuery.desktop}) {
    max-width: 1204px;
    max-height: 520px;
    margin-left: 36%;
    right: 60px;
    bottom: 50px;

    @media (min-height: 600px) {
      padding: 50px 50px 0 105px;
    }
  }
`;

export const Trends = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
`;

export const TrendList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-auto-flow: column;
  @media (${deviceMediaQuery.desktop}) and (min-height: 600px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Header = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 13px;
`;

export const TrendRow = styled.a`
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  margin-top: 30px;

  @media (${deviceMediaQuery.desktop}) {
    font-size: 24px;
    line-height: 30px;
  }

  @media (${deviceMediaQuery.desktop}) and (min-height: 690px) {
    font-size: 27px;
    line-height: 30px;
  }

  @media (${deviceMediaQuery.largeDesktop}) and (min-height: 650px) {
    font-weight: 300;
    font-size: 30px;
    line-height: 33px;
    margin-top: 45px;
  }

  :first-child {
    margin-top: 32px;

    @media (${deviceMediaQuery.largeDesktop}) {
      margin-top: 20px;
    }
  }
`;

export const TrendOrder = styled.span`
  font-weight: 500;
`;
