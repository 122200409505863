import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
html, body {
  font-family: "Graphik", "Helvetica Neue", "Arial", sans-serif;
  font-size: 18px;
  line-height: 20px;
}

a {
  color: inherit;
  text-decoration: none;
  :hover {
  }
}

*:focus {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

* {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

`
