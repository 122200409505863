import React, { useMemo } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { resourceUrl } from "../../util/resourceUrl"
import { Menu as MenuComponent } from "@motherson-dxp/components"

type MenuProps = {
  open: false
  onClose: () => void
}

const _Menu: React.FC<MenuProps> = props => {
  const data: QueryData = useStaticQuery(query)

  const entries = useMemo(
    () =>
      data.trends.edges.map(e => ({
        order: e.node.order,
        label: e.node.name,
        props: {
          as: Link,
          to: resourceUrl(e.node),
        },
      })),
    [data]
  )

  const header = useMemo(
    () => ({ label: "Intro", props: { as: Link, to: "/#1" } }),
    []
  )

  return <MenuComponent entries={entries} header={header} {...props} />
}

type QueryData = {
  trends: {
    edges: [
      {
        node: {
          __typename: string
          name: string
          order: number
          parent: {
            name: string
          }
        }
      }
    ]
  }
}

export const Menu = React.memo(_Menu)

const query = graphql`
  {
    trends: allTrendBootYaml(sort: { fields: order }) {
      edges {
        node {
          __typename
          name
          order
          parent {
            ... on File {
              name
            }
          }
        }
      }
    }
  }
`
