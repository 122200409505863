import styled, { css } from "styled-components"
import { ArrowedButton } from "../components/ArrowedButton"

export const Main = styled.main`
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .fill {
    flex: 1;
  }

  .fill-video video {
    object-fit: cover;
  }
  overflow-x: scroll;
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const MenuButton = styled(ArrowedButton)<{
  $rotate: boolean
  visible: boolean
}>`
  justify-content: center;
  display: ${({ visible }) => !visible && "none"};
  ${({ $rotate }) =>
    $rotate &&
    css`
      svg {
        padding: 2px 0 0;
        transform: rotate(-45deg);
      }
      span {
        margin-right: 15px;
      }
    `};
`
