import styled from "styled-components"
import device from "../../util/devices"

export const Header = styled.header`
  z-index: 50;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 10px 0;
  //border: 0.5px solid #130304;
  min-height: 54px;
  box-sizing: border-box;

  @media (${device.tablet}) {
    padding: 20px 20px 0;
  }
`
