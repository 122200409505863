import styled from "styled-components"

export const Background = styled.div<{ opacity?: number }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  z-index: 9900;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-items: center;
  opacity: ${props => props.opacity};
  transition: opacity 1350ms ease-out;
`

export const RingWrapper = styled.div`
  position: relative;
`

export const LoadingLabel = styled.span`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translateY(-50%);

  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
`
