import React, {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import * as S from "./styled";
import { ProgressRing } from "./ProgressRing";
import { LoaderTheme } from "../../theme/LoaderTheme";
import { ThemeProvider } from "styled-components";
import { FluidObject } from "gatsby-image";
import { ImageSupportContext } from "../../context/ImageSupportContext";

export type Preloadable = {
  type: "image" | "video";
  url: string;
  urlWebp?: string;
  fluid?: FluidObject;
};

type LoaderProps = {
  onReady: () => void;
  preload: Preloadable[];
};

const preloadSupported = () => {
  const relList = document.createElement("link").relList;
  return relList && relList.supports && relList.supports("preload");
};

const PreloaderItem: React.FC<{
  ready: boolean;
  strategy: "preload" | "fallback";
  imageSupport: "webp" | "legacy";
  preload: Preloadable;
  setReady: (p: Preloadable) => void;
}> = ({ strategy, imageSupport, preload, ready, setReady }) => {
  const ref = createRef<HTMLVideoElement>();
  useEffect(() => {
    ref.current?.play();
  });
  switch (preload.type) {
    case "image":
      const imageUrl =
        preload.urlWebp && imageSupport === "webp"
          ? preload.urlWebp
          : preload.url;
      return strategy === "preload" ? (
        <link
          rel="preload"
          as="image"
          href={imageUrl}
          onLoad={() => setReady(preload)}
        />
      ) : ready ? null : (
        <img src={imageUrl} onLoad={() => setReady(preload)} />
      );
    case "video":
      return ready ? null : (
        <video
          ref={ref}
          src={preload.url}
          preload="auto"
          muted
          loop
          playsInline
          onLoad={() => {
            console.log("Video loaded");
            setReady(preload);
          }}
          onCanPlay={() => console.log("Video can play")}
          onCanPlayThrough={() => {
            console.log("Video can play through");
            setReady(preload);
          }}
        />
      );
    default:
      return null;
  }
};

const MemoizedPreloaderItem = React.memo(PreloaderItem);

export const Preloader: React.FC<LoaderProps> = ({ onReady, preload }) => {
  const [progress, setProgress] = useState(0);
  const [hidden, setHidden] = useState(false);
  const [fading, setFading] = useState(false);

  const [strategy, setStrategy] = useState<null | "preload" | "fallback">(null);
  useEffect(() => {
    setStrategy(preloadSupported() ? "preload" : "fallback");
  }, []);

  const [readyStates, setReadyStates] = useState<boolean[]>([]);

  useEffect(() => setReadyStates(preload.map(() => false)), [
    JSON.stringify(preload),
  ]);

  useEffect(() => {
    if (readyStates.length === 0) return;
    const progress = readyStates.filter((s) => s).length / readyStates.length;
    setProgress(progress * 100);
  }, [JSON.stringify(readyStates)]);

  useEffect(() => {
    if (progress < 100) return;
    setTimeout(() => {
      setFading(true);
      setTimeout(() => {
        onReady();
        setHidden(true);
      }, 1500);
    }, 1500);
  }, [progress]);

  const imageSupport = useContext(ImageSupportContext);

  const setItemReady = useCallback(
    (p: Preloadable) => {
      const index = preload.findIndex((px) => px === p);
      setReadyStates((s) => {
        const newState = [...s];
        newState[index] = true;
        return newState;
      });
    },
    [JSON.stringify(preload)]
  );

  useEffect(() => {
    console.log(
      readyStates.map((ready, i) => !ready && preload[i]).filter((x) => !!x)
    );
  }, [JSON.stringify(readyStates)]);

  if (hidden) {
    return null;
  }
  return (
    <>
      <div id="preloading">
        {strategy &&
          imageSupport &&
          preload.map((item, i) => (
            <React.Fragment key={i}>
              <MemoizedPreloaderItem
                ready={readyStates[i]}
                strategy={strategy}
                imageSupport={imageSupport}
                preload={item}
                setReady={setItemReady}
              />
            </React.Fragment>
          ))}
      </div>
      <ThemeProvider theme={LoaderTheme}>
        <S.Background opacity={fading ? 0 : 1}>
          <S.RingWrapper>
            <ProgressRing stroke={1} radius={112.5} progress={progress} />
            <S.LoadingLabel>Loading future exhibition</S.LoadingLabel>
          </S.RingWrapper>
        </S.Background>
      </ThemeProvider>
    </>
  );
};
