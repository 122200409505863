import React from "react"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { useMedia } from "use-media"
import { ThemeProvider } from "styled-components"
import { DarkTheme } from "../../theme/DarkTheme"
import * as S from "./styled"
import { ArrowedButton } from "../ArrowedButton"
import { size } from "../../util/devices"
import { ViewportContext } from "../../context/ViewportContext"

function iOS() {
  if (typeof navigator === "undefined") return false
  return [
    //'iPad Simulator',
    "iPhone Simulator",
    "iPod Simulator",
    //'iPad',
    "iPhone",
    "iPod",
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  //|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

function isDevelopmentEnv() {
  return process.env.NODE_ENV === "development"
}

export const LandscapeFullScreenOnly: React.FC = props => {
  const fullScreen = useFullScreenHandle()

  const isDesktop = useMedia({ minWidth: size.desktop })
  const isPortrait = useMedia({ orientation: "portrait" })

  const ready =
    isDevelopmentEnv() ||
    isDesktop ||
    fullScreen.active ||
    (!isPortrait && iOS())

  return (
    <ViewportContext.Provider
      value={{
        viewportReady: ready,
      }}
    >
      <ThemeProvider theme={DarkTheme}>
        <FullScreen handle={fullScreen}>
          {!ready ? (
            <S.OverlayContainer fixed={!iOS()}>
              {isPortrait ? (
                <>For the best experience please tilt your phone</>
              ) : (
                !fullScreen.active &&
                !iOS() && (
                  <>
                    Just one more thing
                    <ArrowedButton withBackground onClick={fullScreen.enter}>
                      Go full screen
                    </ArrowedButton>
                  </>
                )
              )}
            </S.OverlayContainer>
          ) : null}
          {props.children}
        </FullScreen>
      </ThemeProvider>
    </ViewportContext.Provider>
  )
}
