import styled, { css } from "styled-components"

export const OverlayContainer = styled.div<{ fixed: boolean }>`
  ${({ fixed }) =>
    fixed
      ? css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        `
      : css`
          position: relative;
          min-height: 100vh;
          min-width: 100%;
        `};
  background-color: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  z-index: 200;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;

  button {
    margin-top: 15px;
  }
`

export const Filler = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor};
  position: absolute;
  top: calc(100vh - 150px);
  height: 1500vh;
  width: 100vw;
`
