export const resourceUrl = ({
  __typename,
  parent: { name },
}: {
  __typename: string
  parent: { name: string }
}) => {
  switch (__typename) {
    case "TrendBootYaml":
      return `?boot=1&trend=${name}`
    case "ProductYaml":
      return `/product/${name}/`
    default:
      return ""
  }
}
