import * as React from "react"
import styled from "styled-components"

type ProgressRingProps = {
  radius: number
  stroke: number
  progress: number
  [other: string]: any
}

const SVG = styled.svg`
  circle {
    transition: stroke-dashoffset 0.85s ease-out;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke: ${({ theme }) => theme.textColor};
  }
`

/**
 * Adapted from https://css-tricks.com/building-progress-ring-quickly/
 */
export const ProgressRing: React.FC<ProgressRingProps> = ({
  radius,
  stroke,
  progress,
  ...props
}) => {
  const normalizedRadius = radius - stroke * 2
  const circumference = normalizedRadius * 2 * Math.PI
  const strokeDashoffset = circumference - (progress / 100) * circumference

  return (
    <SVG height={radius * 2} width={radius * 2} {...props}>
      <circle
        stroke="red"
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={circumference + " " + circumference}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </SVG>
  )
}
