import React, { useContext, useEffect } from "react"

const defaultValue: {
  menuButtonVisible: boolean
  setMenuButtonVisible: (a: boolean) => void
  menuContent: any
  setMenuContent: (a: any) => void
} = {
  menuButtonVisible: false,
  setMenuButtonVisible: () => {},
  menuContent: React.Fragment,
  setMenuContent: () => {},
}

export const HeaderPropsContext = React.createContext(defaultValue)

export const PageHeader: React.FC<{
  menuButtonVisible: boolean
  menuContent?: any
}> = ({ menuButtonVisible, menuContent }) => {
  const { setMenuContent, setMenuButtonVisible } = useContext(
    HeaderPropsContext
  )
  useEffect(() => {
    const i = setTimeout(() => {
      setMenuContent(menuContent)
      setMenuButtonVisible(menuButtonVisible)
    }, 500)

    return () => clearTimeout(i)
  }, [menuButtonVisible, menuContent])
  return null
}
