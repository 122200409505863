import React, { useEffect, useState } from "react";
import { check_webp_feature } from "../util/webp-check";

export const ImageSupportContext = React.createContext<
  null | "webp" | "legacy"
>(null);

export const WithImageSupportContext: React.FC = (props) => {
  const [imageSupport, setImageSupport] = useState<null | "webp" | "legacy">(
    null
  );

  useEffect(() => {
    check_webp_feature("animation", (feature: string, supported: boolean) =>
      setImageSupport(supported ? "webp" : "legacy")
    );
  }, []);

  return (
    <ImageSupportContext.Provider value={imageSupport}>
      {props.children}
    </ImageSupportContext.Provider>
  );
};
