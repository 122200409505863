import React, { useCallback, useContext, useState } from "react"
// @ts-ignore
import img from "../../content/media/Motherson_BG.jpg"
import { Content, Main, MenuButton } from "./BaseLayout.styled"
import { ThemeProvider } from "styled-components"
import { LightTheme } from "../theme/Theme"
import { Header } from "../components/Header"
import { Menu } from "../components/Menu"
import { HeaderPropsContext } from "../context/HeaderPropsContext"
// @ts-ignore
import { ReactComponent as Close } from "../../static/icons/ic-close.svg"
import { MenuContext } from "../context/MenuContext"

export const baseBackground = img

type BaseLayoutProps = {
  [other: string]: any
}

export const BaseLayout: React.FC<BaseLayoutProps> = ({
  children,
  ...props
}) => {
  const [menuVisible, setMenuVisible] = useState<boolean>(false)
  const { menuButtonVisible, menuContent } = useContext(HeaderPropsContext)

  const closeMenu = useCallback(() => setMenuVisible(false), [setMenuVisible])
  return (
    <>
      <ThemeProvider theme={LightTheme}>
        <Main style={{ position: "relative" }} {...props}>
          <Header>
            {menuContent}
            <MenuButton
              withBackground
              clickable
              arrowIcon={menuVisible && <Close />}
              $rotate={!menuVisible}
              onClick={() => setMenuVisible(s => !s)}
              visible={menuButtonVisible}
            >
              {menuVisible ? "Close" : <span>See all trends</span>}
            </MenuButton>
          </Header>
          <Content>
            <MenuContext.Provider
              value={{ setMenuOpen: () => setMenuVisible(true) }}
            >
              {children}
            </MenuContext.Provider>
            {/*@ts-ignore*/}
            <Menu open={menuVisible} onClose={closeMenu} />
          </Content>
        </Main>
      </ThemeProvider>
    </>
  )
}
