import React, { useState } from "react"
import { StyleSheetManager } from "styled-components"
import griddie from "styled-griddie"
import { Normalize } from "styled-normalize"
import { GlobalStyles } from "../theme/global"
import { BaseLayout } from "../layout/BaseLayout"
import { HeaderPropsContext } from "../context/HeaderPropsContext"
import { LandscapeFullScreenOnly } from "./LandscapeFullScreenOnly"
import { WithImageSupportContext } from "@motherson-dxp/components"

import { Helmet } from "react-helmet"

export const PageWrapper: React.FC = ({ children }) => {
  const [menuButtonVisible, setMenuButtonVisible] = useState<boolean>(false)
  const [menuContent, setMenuContent] = useState<any>()

  return (
    <StyleSheetManager stylisPlugins={[griddie]}>
      <WithImageSupportContext>
        <Helmet>
          <link rel="stylesheet" href="/fonts/graphik.css" />
        </Helmet>
        <GlobalStyles />
        <Normalize />
        <HeaderPropsContext.Provider
          value={{
            menuButtonVisible: menuButtonVisible,
            setMenuButtonVisible,
            menuContent: menuContent,
            setMenuContent,
          }}
        >
          <LandscapeFullScreenOnly>
            <BaseLayout>{children}</BaseLayout>
          </LandscapeFullScreenOnly>
        </HeaderPropsContext.Provider>
      </WithImageSupportContext>
    </StyleSheetManager>
  )
}
